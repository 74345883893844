import React from 'react'
import {Box, Text} from 'grommet'
import CalendarBox from './CalendarBox'

import format from 'date-fns/format'
import eachDay from 'date-fns/eachDayOfInterval'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'

const getWeekdays = () => {
    const now = new Date()
    return eachDay({start: startOfWeek(now), end: endOfWeek(now)}).map(day =>
        format(day, 'EEE'),
    )
}

const Weekdays = () => {
    const weekdays = getWeekdays()
    return (
        <Box direction="row" wrap>
            {weekdays.map(weekday => (
                <CalendarBox
                    border={{color: 'calendar-weekdays-border'}}
                    background="calendar-weekdays-background"
                    pad="small"
                    key={weekday}
                >
                    <Text
                        textAlign="center"
                        color="calendar-weekdays-text"
                        truncate
                        a11yTitle={weekday}
                    >
                        {weekday}
                    </Text>
                </CalendarBox>
            ))}
        </Box>
    )
}

export default Weekdays
