import PropTypes from 'prop-types'
import React from 'react'
import {Box, ResponsiveContext, Text} from 'grommet'
import Event from './Event'
import ConfigContext from "./ConfigContext";
import {css} from "styled-components";
import {sortByEvent} from "./utils/sortByEvent";

const Events = ({events, hasPast}) => {

    if (events.length <= 0) {
        return null;
    }

    return (
        <ResponsiveContext.Consumer>
            {size => (
                <ConfigContext.Consumer>
                    {({maxAmountEvents}) => (
                        <Box
                            gap={size === 'small' ? 'small' : 'xsmall'}
                            pad="none"
                            tag="ul"
                            overflow="auto"
                            css={css`
                margin-top: 0;
                margin-bottom: 0;
                list-style: none;
              `}
                        >
                            {events.slice(0, maxAmountEvents + 1).sort(sortByEvent).map((event, i) => (
                                <Box tag="li" key={event.id} css={css`
                                margin: 0px;
                                list-style: none;
              `}>
                                    {i < maxAmountEvents && <Event event={event} hasPast={hasPast}/>}
                                    {i === maxAmountEvents && (
                                        <Text size="small" truncate>
                                            {`And ${events.length - maxAmountEvents} more ...`}
                                        </Text>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    )}
                </ConfigContext.Consumer>
            )}
        </ResponsiveContext.Consumer>
    )
}


Events.propTypes = {
    events: PropTypes.array,
}

export default Events
