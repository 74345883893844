import React from 'react'
import {Box, Button, Layer, Text} from 'grommet'
import format from 'date-fns/format'
import PropTypes from 'prop-types'
import {sortByEvent} from "./utils/sortByEvent";

const ModalEvent = ({hideModal, modalState}) => {
    const {currentDay, events} = modalState;

    return (
        <Layer position="center" onClickOutside={hideModal} onEsc={hideModal} modal>
            <Box
                direction="row"
                align="center"
                tag="header"
                elevation="small"
                justify="between"
            >
                <Text
                    margin={{left: 'small'}}
                    color="calendar-modal-text"
                    a11yTitle="Selected day"
                >
                    <b>{format(new Date(currentDay), 'EEEE d, MMMM')}</b>
                </Text>
                <Button
                    style={{color: 'black'}}
                    label='Close'
                    a11yTitle="Close popup button"
                    onClick={hideModal}
                />
            </Box>
            <Box
                direction="column"
                align="center"
                tag="section"
                margin="medium"
                gap="small"
            >
                {events.sort(sortByEvent).map(event => (
                    <Box
                        elevation="small"
                        direction="row"
                        fill="horizontal"
                        background="calendar-modal-background"
                    >
                        <Box
                            margin="small"
                            background={`calendar-${event.event}-event-background`}
                        >
                            <Text
                                a11yTitle="Event name"
                                weight="bold"
                                size="large"
                                color="calendar-modal-text"
                            >
                                {event.event}
                            </Text>

                            {event.description && (
                                <Text a11yTitle="Description" color="calendar-modal-text">
                                    {event.description}
                                </Text>
                            )}
                        </Box>
                    </Box>
                ))}
            </Box>
        </Layer>
    )
}

ModalEvent.propTypes = {
    hideModal: PropTypes.func.isRequired,
    modalState: PropTypes.object
}

export default ModalEvent
