import React, {useState} from 'react'
import Month from './Month'
import data from './event-data'
import ConfigContext from "./ConfigContext";
import {Grommet} from "grommet";
import {grommet} from "grommet/themes";
import config from "./calendar-config";
import {css} from "styled-components";
import groupEventsByMonth from "./utils/groupEventsByMonth";
import flatObject from "./utils/flatObject";
import {Section} from "../../global";
import ModalEvent from "./ModalEvent";

const enhancedTheme = (baseTheme, customTheme) => {
    const flatTheme = flatObject(customTheme);
    const colors = {
        ...flatTheme,
    };

    return {
        ...baseTheme,
        global: {
            ...baseTheme.global,
            colors,
        },
    }
}

const INITIAL_STATE = {
    currentDay: new Date(),
    events: [],
    visible: false,
}

const Calendar = () => {
    const [modalState, setModalState] = useState(INITIAL_STATE);

    const showModal = (events, currentDay) => {
        setModalState({ events, currentDay, visible: true})
    };

    const hideModal = () => {
        setModalState(INITIAL_STATE);
    }

    return (
        <Section>
            <ConfigContext.Provider>
                <Grommet
                className='special-calendar-styling'
                    theme={enhancedTheme(grommet, config.theme)}
                    full
                    css={css`scroll-behavior: smooth; overflow-y: scroll;`}
                >
                    <ConfigContext.Consumer>
                        {({limitMonthInTheFuture}) => (
                            groupEventsByMonth(data, limitMonthInTheFuture).map(monthlyCalendar => (
                                <Month
                                    monthlyCalendar={monthlyCalendar}
                                    showModal={showModal}
                                    key={monthlyCalendar.date}
                                />
                            ))
                        )}
                    </ConfigContext.Consumer>
                    {modalState.visible && (
                        <ModalEvent
                            hideModal={hideModal}
                            modalState={modalState}
                        />
                    )}
                </Grommet>
            </ConfigContext.Provider>
        </Section>
    )
}

export default Calendar
