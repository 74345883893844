import PropTypes from 'prop-types'
import { Box, Text } from 'grommet'
import React from 'react'
import {css} from 'styled-components'

const Event = ({ event, hasPast }) => {
  const background = hasPast ? 'calendar-past-event-background' : `calendar-${event.event}-event-background`;
  const textColor = hasPast ? 'calendar-past-event-text' : 'calendar-event-text'

  return (
    <Box round="xsmall" background={background} pad="2px">
      <Text
        size="small"
        truncate
        color={textColor}
        a11yTitle="Event name"
        css={css`
          font-weight: ${!hasPast && 700};
        `}
      >
        {event.event}
      </Text>
    </Box>
  )
}

Event.propTypes = {
  event: PropTypes.object.isRequired,
}

export default Event
