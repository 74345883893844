import Layout from '../components/common/Layout';
import Navbar from '../components/common/Navbar';
import Footer from '../components/sections/Footer';
import React from 'react';
import Calendar from "../components/sections/Calendar/Calendar";

const CalendarPage = () => {
    return (
        <Layout>
            <Navbar/>
            <Calendar/>
            <Footer/>
        </Layout>
    );
}

export default CalendarPage;
