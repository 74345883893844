const calendarTheme = {
  background:
    'linear-gradient(to right bottom, #d22780, #b82283, #9b2085, #7e2183, #5e227f)',
  brand: '#1fe5bd',
  secondary: '#41a7b3',
  focus: '#1fe5bd',
  text: 'white',

  calendar: {
    today: {
      background: '#9af1daDD',
      text: 'white',
      border: 'white',
    },
    day: {
      background: '#ffffffbb',
      text: 'black',
      border: 'white',
    },
    past: {
      background: '#ffffff73',
      text: 'black',
      border: 'white',
    },
    empty: {
      background: 'transparent',
      border: 'white',
    },
    weekdays: {
      background: '#ffffff33',
      border: 'white',
    },
    'lunch-event': {
      background: '#00A8B5',
      text: 'white',
    },
    'Lunch-event': {
      background: '#00A8B5',
      text: 'white',
    },
    'breakfast-event': {
      background: '#00A8B5',
      text: 'white',
    },
    'Breakfast-event': {
      background: '#00A8B5',
      text: 'white',
    },
    'snack-event': {
      background: '#00A8B5',
      text: 'white',
    },
    'Snack-event': {
      background: '#00A8B5',
      text: 'white',
    },
    'trip-event': {
      background: '#562C7F',
      text: 'white',
    },
    'Trip-event': {
      background: '#562C7F',
      text: 'white',
    },
    'announcement-event': {
      background: '#DE4383',
      text: 'white',
    },
    'Announcement-event': {
      background: '#DE4383',
      text: 'white',
    },
    'past-event': {
      background: 'grey',
      text: 'white',
    },
    modal: {
      text: 'black',
      background: 'white',
    },
  },
};

module.exports = {
  title: 'Gatsby Starter Event Calendar',
  subTitle: 'The easiest option to share events!',
  maxAmountEvents: 5,
  limitMonthInTheFuture: 2,
  theme: calendarTheme,
};
